<template>
    <div class="banner-container">
        <a-card title="新闻资讯管理">
            <div class="tool">
                <div>
                    <span>文章标题：</span>
                    <a-select style="width: 200px;margin-right: 10px" v-model="searchTitle" placeholder="请选择" allowClear @change="newsSelectChange">
                        <a-select-option v-for="(item,i) in searchTitleList" :key="i" :value="item.label">{{item.label}}</a-select-option>
                    </a-select>
                    <span>文章类型：</span>
                    <a-select style="width: 200px" v-model="searchType" placeholder="请选择" allowClear @change="newsSelectChange">
                        <a-select-option v-for="(item,i) in searchTypeList" :key="i" :value="item.label">{{item.label}}</a-select-option>
                    </a-select>
                </div>
                <a-button type="primary" icon @click="add"><a-icon type="plus"></a-icon>新增</a-button>
            </div>
            <div class="table">
                <a-table :rowKey="(item,i)=>item.id" :columns="columns" :data-source="tableData" :pagination="pagination" :loading="loading"></a-table>
            </div>
        </a-card>
        <a-modal :width="800" v-model="editWindowShow" :title="modalTitle" @ok="modalOk" cancelText="取消" okText="确定" :confirmLoading="confirmLoading">
            <a-form-model ref="form" :rules="formRules" :model="formData" :label-col="{span:5}" :wrapper-col="{span:12}">
                <div class="topMenu">
                    <a-form-model-item style="width: 50%" label="文章名称：" prop="name">
                        <a-input style="width: 200px" v-model="formData.name" placeholder="请输入名称"></a-input>
                    </a-form-model-item>
                    <a-form-model-item style="width: 50%" label="文章类型：" prop="type">
                        <a-select style="width: 200px" v-model="formData.type" placeholder="请选择" allowClear @change="selectChange">
                            <a-select-option v-for="(item,i) in searchTypeList" :key="i" :value="item.label">{{item.label}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item style="width: 50%" label="发布人：" prop="publisher">
                        <a-input style="width: 200px" v-model="formData.publisher" placeholder="请输入发布人"></a-input>
                    </a-form-model-item>
                    <a-form-model-item style="width: 50%" label="首页展示：" prop="homeShow">
                        <a-switch v-model="formData.homeShow"></a-switch>
                    </a-form-model-item>
                </div>
                <a-form-model-item :label-col="{span:3}" required label="文章内容：" prop="content">
                    <div class="editor" v-if="editWindowShow">
                        <Toolbar style="border-bottom: 1px solid #ccc;" :editor="editorRef" :defaultConfig="editorConfig.toolbarConfig" :mode="mode" />
                        <Editor  v-model="formData.content" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
                    </div>
                </a-form-model-item>
                <a-form-model-item :label-col="{span:3}" required label="封面上传：" prop="coverImg">
                    <upload ref="upload" action="" upload-length="1" @uploadChange="handleChange"></upload>
                </a-form-model-item>
            </a-form-model>
            <template slot="footer">
               <a-popconfirm title="确定取消？" @confirm="cancel" okText="确定" cancelText="返回">
                   <a-button key="back">取消</a-button>
               </a-popconfirm>
                <a-button  type="primary" @click="modalOk('save')">保存草稿</a-button>
                <a-button key="submit" type="primary" @click="modalOk('publish')">发布</a-button>

            </template>
        </a-modal>
    </div>
</template>

<script>
import {h} from "vue";
import upload from "@/components/upload.vue";
import { toolbarConfig, MENU_CONF } from '@/utils/wangeditors.js'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {saveOrEditNews,selectNews,deleteNews} from '@/api/apis'
import handleRequest from "@/utils/handleRequest";
export default {
    name: "newsOption",
    components:{upload,Editor, Toolbar},
    data() {
        return {
            mode:'default',
            editorRef: null,
            editorConfig: {
                placeholder: '请输入内容...',
                //配置工具栏
                toolbarConfig: toolbarConfig,
                MENU_CONF: MENU_CONF,
            },
            editWindowShow:false,
            modalTitle:'新增',
            searchTitle:'',
            searchType:'',
            searchTitleList:[],
            searchTypeList:[
                {
                    label:'政策分析',
                    id:'1'
                },
                {
                    label:'行业动态',
                    id:'2'
                },
                {
                    label:'销售平台',
                    id:'3'
                },
                {
                    label:'制造平台',
                    id:'4'
                },
            ],
            formData:{
                sort:'',
                name:'',
                type:'',
                content:'',
                publisher:'',
                coverImg:'',
                publishTime:'',
                state:'',
                homeShow:false
            },
            loading:false,
            confirmLoading:false,
            pagination:{
                pageSize:10,
                current:1,
                total:0,
                showTotal:(total)=>`共${total}条`,
                onChange:(page,pageSize)=>this.pageChange(page,pageSize)
            },
            formRules: {
                name: [{required:true,message:'请输入名称',trigger:'blur'}],
                type: [{required:true,message:'请选择文章类型',trigger:'blur'}],
                publisher: [{required:true,message:'请输入发布人',trigger:'blur'}],
                content:[{required:true,message:'请输入内容',trigger:'blur'}],
                coverImg: [{validator: (rule,value,callback)=>{
                        if(!this.formData.coverImg.length){
                            callback('请至少上传一张')
                        }else if(this.formData.coverImg.length>1){
                            callback('封面只能上传一张')
                        }else{
                            return true
                        }
                    },trigger:'blur'}],
            },
            columns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    customRender: (text,record,index) => `${index+1}`,
                },
                {
                    align: 'center',
                    dataIndex:'name',
                    key:'name',
                    title:'文章标题',
                    ellipsis: true,
                },
                {
                    align: 'center',
                    dataIndex:'type',
                    key:'type',
                    title:'文章类型',
                },
                {
                    align: 'center',
                    dataIndex:'createTime',
                    key:'createTime',
                    title:'发布时间',
                },
                {
                    align: 'center',
                    dataIndex:'publishTime',
                    key:'publishTime',
                    title:'编辑时间',
                },
                {
                    align: 'center',
                    dataIndex:'state',
                    key:'state',
                    title:'状态',
                    customRender:(text, record, index)=> `${record.state == 2 ? '未发布':'已发布'}`
                },
                {
                    align: 'center',
                    dataIndex:'clickNum',
                    key:'clickNum',
                    title:'浏览量',
                    customRender:(text, record, index)=> `${record.clickNum? record.clickNum:0}次`
                },
                {
                    align: 'center',
                    dataIndex:'action',
                    key:'action',
                    title:'操作',
                    width:250,
                    customRender:(text,record,index)=>{
                        return h("div",[
                            h("a-popconfirm",{
                                props:{
                                    title:`确定要${record.state == 2 ? '发布':'下架'}吗？`,
                                    okText:'确定',
                                    cancelText:'取消',
                                },
                                style:{
                                    marginRight:10+'px'
                                },
                                on:{
                                    'confirm':()=>{
                                        this.activeNews(record)
                                    }
                                }
                            },[
                                h("a-button",{
                                    props:{
                                        icon:record.state == 2 ? 'arrow-up':'arrow-down',
                                        type: 'primary',
                                        size:'small',
                                    }
                                },record.state == 2 ? '发布':'下架')
                            ]),
                            h("a-button", {
                                style:{
                                    marginRight:'10px'
                                },
                                props:{
                                    icon:'edit',
                                    type:'primary',
                                    size:'small'
                                },
                                on:{
                                    click:()=>{
                                        this.edit(record)
                                    }
                                }
                            }, '编辑'),
                            h("a-popconfirm",{
                                props:{
                                    title:'确定要删除吗？',
                                    okText:'确定',
                                    cancelText:'取消',
                                },
                                on:{
                                    'confirm':()=>{
                                        this.delete(record)
                                    }
                                }
                            },[
                                h("a-button",{
                                    props:{
                                        icon:'delete',
                                        type: 'danger',
                                        size:'small',
                                    }
                                },'删除')
                            ])
                        ])
                    }
                },
            ],
            tableData:[],
        }
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        async getTableData(){
            let params = {
                name:this.searchTitle,
                type:this.searchType,
                page:this.pagination.current,
                pageSize: this.pagination.pageSize,
            }
            const data = handleRequest(await selectNews(params))
            this.tableData = data.content
            this.searchTitleList = this.tableData.map(item=>{
                return {
                    label:item.name
                }
            })
        },
        handleCreated(editor) {
            this.editorRef = editor // 记录 editor 实例
        },
        async activeNews(params){
            console.log('当前下架的新闻',params)
            params.state = params.state == 2 ? 1:2
            handleRequest(await saveOrEditNews(params),res=>{
                if(res.code == 200){
                    this.$message.success('操作成功')
                    this.getTableData()
                }
            })
        },
        handleChange(list){
            console.log('上传完成的图片：',list)
            this.formData.coverImg = list
        },
        cancel(){
            this.editWindowShow = false
            this.formData = {
                sort:'',
                name:'',
                type:'',
                content:'',
                publisher:'',
                coverImg:'',
                publishTime:'',
                state:'',
                homeShow:false
            }
        },
        modalOk(status){
            this.confirmLoading = true
            console.log('编辑窗口确定')
            this.$refs.form.validate(async valid => {
                if (valid) {
                    let params = this.formData
                    params.coverImg = JSON.stringify(params.coverImg)
                    params.publishTime = this.dateFormat(new Date(),'y-m-d h:M:s')
                   if(status == 'save'){
                       params.state = 2
                       handleRequest(await saveOrEditNews(params),res=>{
                           if(res.code == 200){
                               this.$message.success('保存草稿成功')
                           }
                       })
                   }else{
                       params.state = 1
                       handleRequest(await saveOrEditNews(params),res=>{
                           if(res.code == 200){
                               this.$message.success('发布成功')
                           }
                       })
                   }
                    this.confirmLoading = false
                    this.editWindowShow = false
                    this.getTableData()
                } else {
                    console.log('验证错误');
                    console.log(this.formData)
                    return false;
                }
            });
        },
        newsSelectChange(value){
            this.getTableData()
        },
        selectChange(value){
            this.getTableData()
        },
        add(){
            this.editWindowShow = true
            this.modalTitle = '新增'
            this.$nextTick(()=>{
                this.$refs.upload.imgList = []
            })
            this.formData = {
                sort:this.tableData.length+1,
                name:'',
                type:'',
                content:'',
                state:'',
                publisher:'',
                coverImg:'',
                homeShow:false
            }
        },
        edit(params){
            this.editWindowShow = true
            this.modalTitle = '编辑'
            this.formData = params
            if(typeof this.formData.coverImg == "string"){
                this.formData.coverImg = JSON.parse(this.formData.coverImg)
            }
            this.$nextTick(()=>{
                this.$refs.upload.imgList = this.formData.coverImg
            })
        },
        async delete(params){
            console.log(params)
            handleRequest(await deleteNews({id:params.id}),res=>{
                if(res.code == 200){
                    this.$message.success('删除成功')
                    this.getTableData()
                }
            })
        }
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
::v-deep .w-e-scroll{
    min-height: 300px;
}
.editor{
    z-index: 999999;
    width: 600px;
    min-height: 300px;
    border: 1px solid #8d8d8d;
}
.topMenu{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.banner-container{
    padding: 20px;
    .tool{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }
    .table{
        margin-top: 30px;
    }

}
</style>
