import { render, staticRenderFns } from "./newsOption.vue?vue&type=template&id=59651cd1&scoped=true"
import script from "./newsOption.vue?vue&type=script&lang=js"
export * from "./newsOption.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./newsOption.vue?vue&type=style&index=1&id=59651cd1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59651cd1",
  null
  
)

export default component.exports